<template>
  <header
    class="page-header contain-override"
    :class="containerClasses"
    :style="containerStyles"
  >
    <div class="heading space-y-8" :class="headingClasses">
      <slot name="heading">
        <h1
          v-if="hasTitle"
          class="page-title text-5xl font-serif font-bold"
          :class="transformClasses(classes?.title)"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </h1>

        <h2
          v-if="subtitle || slots.subtitle"
          class="page-subtitle space-y-4 text-2xl font-serif font-bold"
        >
          <slot name="subtitle">
            {{ subtitle }}
          </slot>
        </h2>
      </slot>

      <slot />
    </div>
  </header>
</template>

<script setup lang="ts">
import type { ContentImage } from '~/types'

const colors = [
  'cornflower',
  'moss',
  'peacock',
  'midnight',
  'sky',
  'marigold',
  'parchment',
  'turquoise',

  'neutral', // gray
  'white',
] as const

const props = defineProps<{
  title: string
  subtitle?: string
  background?: ContentImage
  bgColor?: (typeof colors)[number]
  classes?: Partial<{
    container: string | string[]
    heading: string | string[]
    title: string | string[]
    body: string | string[]
  }>
}>()

const slots = defineSlots()

const hasBackground = computed(() => !!props.background?.src)
const hasTitle = computed(() => props.title || slots.title)
const hasSubtitle = computed(() => props.subtitle || slots.subtitle)

const transformClasses = (classes?: string | string[]) => {
  if (!classes) return {}

  if (!Array.isArray(classes)) classes = [classes]

  return classes.reduce((acc, c) => ({ ...acc, [c]: true }), {})
}

const containerClasses = computed(() => {
  const { bgColor } = props

  const bgColors = {
    'bg-brand-cornflower': bgColor === 'cornflower',
    'bg-brand-moss': bgColor === 'moss',
    'bg-brand-peacock': bgColor === 'peacock',
    'bg-brand-midnight': bgColor === 'midnight',
    'bg-brand-sky': bgColor === 'sky',
    'bg-brand-marigold': bgColor === 'marigold',
    'bg-brand-parchment': bgColor === 'parchment',
    'bg-brand-turquoise': bgColor === 'turquoise',
    'bg-neutral-200': bgColor === 'neutral',
    'bg-white': bgColor === 'white',
  }

  return {
    ...bgColors,
    'has-title': unref(hasTitle),
    'has-subtitle': unref(hasSubtitle),
    'has-background': unref(hasBackground),
    // 'contain-override': true,
    ...transformClasses(props.classes?.container),
  }
})

const headingClasses = computed(() => {
  return {
    contain: unref(hasBackground),
    ...transformClasses(props.classes?.heading),
  }
})

const img = useImage()
const containerStyles = computed(() => {
  if (!props.background) return

  const { src, height, width } = props.background

  const bgImg = img(src, {
    width,
    height,
    quality: 80,
  })

  return {
    '--bg-image': `url(${bgImg})`,
    '--bg-height': `${height}px`,
    '--bg-width': `${width}px`,
  }
})
</script>

<style lang="postcss" scoped>
.page-title {
  view-transition-name: title;
}

.page-header:not([class*='py-'], [class*='pt-'], [class*='pb-']) {
  @apply py-20;
}

.has-title {
  .page-title {
    margin-bottom: 0;
  }
}

.node-page {
  .page-header {
    @apply px-2;
    .page-subtitle {
      @apply text-2xl font-serif italic;
    }
    .subtitle + .byline {
      @apply text-base mt-4;
    }
  }
}

.bg-brand-sky {
  .page-title,
  .byline,
  .subtitle {
    @apply text-brand-peacock;
  }
}

.has-background {
  @apply relative overflow-hidden z-0 bg-transparent;

  .page-title,
  .page-subtitle {
    @apply text-white;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  }

  .byline,
  .subtitle {
    @apply text-white;
  }

  .subtitle + .byline {
    @apply text-base mt-4;
  }
}

.has-background::after {
  @apply bg-center bg-no-repeat bg-cover bg-clip-border bg-opacity-80;

  content: '';
  position: absolute;
  inset: -4px;
  background: var(--bg-image) rgba(0, 0, 0, 0.63);
  filter: blur(4px);
  opacity: 0.85;
  z-index: -1;
  background-position: center;
  background-size: cover !important;
}
</style>
